import React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import IframeForm from '~2-components/IframeForm/IframeForm';
import IframeFormKontent from "~3-data-components/IframeFormKontent";



const iframeFormEg = `import IframeForm from '~2-components/IframeForm/IframeForm';
or
import IframeFormKontent from '~3-data-components/IframeFormKontent';

<IframeForm
    title='' // Required
    formId='' // Required
    imgUrl=''
    imgAlt=''
    embedURL?='' // Optional to create iframe
    embedCode?='' // Required if no embedURL. Uses JS embed code instead of iframe
>
    // blurb content goes here
</IframeForm>
`;

const SgIframeForm = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allContactForms = useStaticQuery(
        graphql`
            query SGAllIframeForms {
                allKontentItemForm {
                    nodes {
                        system {
                            id
                        }
                    }
                }
            }
    `);

    const iframeForms = allContactForms.allKontentItemForm.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Iframe Form</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={iframeFormEg}
                    />

                    <h3>Example using embedURL (iframe method)</h3>
                </div>
            </section>
            <IframeForm
                title='Leave a Message'
                formId='contact_us'
                imgUrl='https://source.unsplash.com/featured/606x808?people'
                imgAlt='image alt text'
                embedURL='https://advicelineinjurylawyers.formstack.com/forms/contact_us'
            >
                <p>Adviceline Injury Lawyers has been assisting injured Victorians for more than 50 years.  We are a leading personal injury practice with a passion for helping our clients access the compensation they need to get their lives back on track.</p>
            </IframeForm>
            <section className="sg-content">
                <div className="container">
                    <h3>Example using embedCode (JS embed method)</h3>
                </div>
            </section>
            <IframeForm
                title='Leave a Message'
                formId='contact_us'
                imgUrl='https://source.unsplash.com/featured/606x808?people'
                imgAlt='image alt text'
                embedCode='<script type="text/javascript" src="https://luminary-test.formstack.com/forms/js.php/digital_ninja_quiz"></script><noscript><a href="https://luminary-test.formstack.com/forms/digital_ninja_quiz" title="Online Form">Online Form - Digital Ninja Quiz</a></noscript><div style="text-align:right; font-size:x-small;"><a href="http://www.formstack.com?utm_source=jsembed&utm_medium=product&utm_campaign=product+branding&fa=h,3320917" title="Powered by Formstack">Powered by Formstack</a></div>'
            >
                <p>Adviceline Injury Lawyers has been assisting injured Victorians for more than 50 years.  We are a leading personal injury practice with a passion for helping our clients access the compensation they need to get their lives back on track.</p>
            </IframeForm>
            <section className="sg-content">
                <div className="container">
                    <h3>All LIVE iframe forms</h3>
                </div>
            </section>
            <section className="project-example">
                {iframeForms.map((item, i) => {
                    return (
                        <IframeFormKontent
                            key={i}
                            {...item}
                        />
                    );
                })}
            </section>
        </SgLayout>
    );
};

export default SgIframeForm;
